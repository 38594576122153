import { useEffect, useState } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import { DataObject, RemoveCircle, FileOpen } from "@mui/icons-material"
import { Box, Button, IconButton, Paper, Stack, Grid, Typography, Menu, MenuItem } from "@mui/material"
import FormsWrapped from "../Components/FormsWrapper"
import schema from "../schemas/gmx-schema.json"
import uischema from "../schemas/gmx-uischema.json"
import { stringify } from 'yaml'
import ProteinViewer from "../Components/ProteinViewer"
import { ErrorObject } from 'ajv'


const Editor = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const fileName = location.state.fileName
    const [metadata, setMetadata] = useState(location.state.metadata || {})
    const [formErrors, setFormErrors] = useState<ErrorObject[] | undefined>(undefined)
    const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null)
    const uniprotId = metadata._uniprot_id

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElem(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorElem(null)
    }

    const downloadMetadata = (format: string): void => {
        const element = document.createElement("a")
        let file = new Blob()
        if (format === "yaml") {
            file = new Blob([stringify(metadata)], { type: 'text/plain' })
        } else if (format === "json") {
            file = new Blob([JSON.stringify(metadata)], { type: 'text/plain' })
        }
        element.href = URL.createObjectURL(file)
        element.download = `${fileName || "unknown"}-${(new Date()).toISOString()}.metadata.${format}`
        document.body.appendChild(element) // Required for this to work in FireFox
        element.click()
        handleClose()
    }

    const clearMetadata = () => {
        setMetadata({})
        navigate("/")
    }

    useEffect(() => {
        if (metadata._uniprot_id) return
        if (!metadata._protein_sequences) return

        let sequence = metadata._protein_sequences[0]

        fetch(`/api/get_uniprot?seq=${sequence}`)
            .then(response => response.text())
            .then(id => setMetadata({ ...metadata, _uniprot_id: id }))
            .catch(error => console.error("Failed to fetch Uniprot ID:", error))

    }, [metadata._uniprot_id, metadata._protein_sequences])

    return (
        <Grid container justifyContent="center" position="relative" width="100vw"  left="-20vw">
            <Box sx={{m: 3}}>
                <Box>
                    <Typography variant="h1" sx={{ mb: 3 }}>Selected File</Typography>
                    <Paper sx={{ p: 2, display: "flex", alignItems: "center", mb: 3, bgcolor: "primary.main" }}>
                        <FileOpen sx={{ mr: 2 }} />
                        <Typography variant="h3">{fileName}</Typography>
                        <IconButton onClick={clearMetadata} size="large" sx={{ml: "auto", color: "#F00"}}><RemoveCircle /></IconButton>
                    </Paper>
                </Box>
                <Box>
                    <Typography variant="h1" sx={{ mb: 3 }}>Analyze Metadata</Typography>
                    <FormsWrapped schema={schema} uischema={uischema} data={metadata} setData={setMetadata} setErrors={setFormErrors} />
                </Box>
                <Box>
                    <Typography variant="h1" sx={{ mb: 3, mt: 3 }}>Download Metadata</Typography>
                    {formErrors && formErrors.length > 0 && 
                        <Stack direction="column" spacing={1} sx={{mt: 3, mb: 3}} color="red">
                            <Typography variant="h3">Errors</Typography>
                            <ul>
                                {formErrors.map((error: ErrorObject, index) => {
                                    // For some reason `error.instancePath` was crashing my build
                                    // so I had to stringify and parse it to get the value :(
                                    const err = JSON.parse(JSON.stringify(error));
                                    return(
                                        <li key={index}>{err.instancePath}: {err.message}</li>
                                    )}
                                )}
                            </ul>
                        </Stack>
                    }
                    <Button endIcon={<DataObject />} size="large" variant="contained" color="primary" disabled={!metadata || (formErrors && formErrors?.length > 0)} sx={{ mt: 0 }} onClick={handleClick}>
                        Select Format
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElem}
                        keepMounted
                        open={Boolean(anchorElem)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => downloadMetadata("json")}>JSON</MenuItem>
                        <MenuItem onClick={() => downloadMetadata("yaml")}>YAML</MenuItem>
                    </Menu>
                </Box>
            </Box>
            <ProteinViewer pdbData={location.state.pdbData} uniprotId={uniprotId} width={500} height={500} />
        </Grid>
    )
}

export default Editor
